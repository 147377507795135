<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="小区名称">
            <el-input v-model="searchForm.community_name" placeholder="请输入 小区名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="小区编码">
            <el-input v-model="searchForm.community_code" placeholder="请输入 小区编码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="楼栋编号">
            <el-input v-model="searchForm.code" placeholder="请输入 楼栋编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="楼栋名称">
            <el-input v-model="searchForm.name" placeholder="请输入 楼栋名称"></el-input>
          </el-form-item>
        </el-col>
      </template>
      <template #BTN>
        <el-button @click="dialogFormVisible = true; title = '新增'" class="searchBtn">新增</el-button>
      </template>
    </Searchbar>

    <basicTable
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        :total="page.total">
    </basicTable>

    <el-dialog
        v-model="dialogFormVisible"
        :title="title"
        :close-on-click-modal="false"
        :before-close="handleClose"
    >
      <el-form
          label-position="right"
          :model="form"
          size="small"
          label-width="120px"
          ref="dataform"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="小区名称" prop="community_code" :rules="[{ required: true, message: '请选择 小区名称' }]">
              <el-select v-model="form.community_code" filterable placeholder="请选择">
                <el-option v-for="item in communityList" :key="item.code" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="楼栋编号" prop="code" :rules="[{ required: true, message: '请输入 楼栋编号' }]">
              <el-input clearable v-model="form.code" placeholder="请输入 楼栋编号" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="楼栋名称" prop="name" :rules="[{ required: true, message: '请输入 楼栋名称' }]">
              <el-input clearable v-model="form.name" placeholder="请输入 楼栋名称" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="onSave">提交</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
        append-to-body
        v-model="editFormVisible"
        title="编辑"
        :close-on-click-modal="false"
        :before-close="handleClose"
    >
      <el-form
          label-position="right"
          :model="form"
          size="small"
          label-width="120px"
          ref="dataform"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="小区名称" prop="community_code" :rules="[{ required: true, message: '请选择 小区名称' }]">
              <el-select v-model="form.community_code" filterable placeholder="请选择">
                <el-option v-for="item in communityList" :key="item.code" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="楼栋编号" prop="code" :rules="[{ required: true, message: '请输入 楼栋编号' }]">
              <el-input clearable v-model="form.code" placeholder="请输入 楼栋编号" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="楼栋名称" prop="name" :rules="[{ required: true, message: '请输入 楼栋名称' }]">
              <el-input clearable v-model="form.name" placeholder="请输入 楼栋名称" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="editSave">提交</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog v-model="resourceVisible" title="绑定设备" width="30%">
      <el-form label-position="right" label-width="100px" :model="formLabelAlign" style="max-width: 460px">
        <el-form-item label="设备">
          <el-select v-model="formLabelAlign.mac" class="m-2" placeholder="请选择 设备" size="large">
            <el-option v-for="item in resourceList" :key="item.mac" :label="item.name" :value="item.mac"/>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="onbind">提交</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { dataList, addData, editData, delData, communityAll, getCommunityResourceAll, bindingResource } from "@/api/popularization/build";
import Searchbar from '@/components/Searchbar';
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  components:{
    Searchbar,
  },
  data(){
    return{
      formLabelAlign:{},
      resourceList: [],
      resourceVisible:false,
      communityList:[],
      editFormVisible:false,
      title:"",
      dialogFormVisible: false,
      form:{},
      searchForm:{},
      tableData:[],
      attrList:[
        {
          prop: "community_name",
          label: "小区名称",
          render: (row) => <div>{row.community_name}</div>,
        },
        {
          prop: "community_code",
          label: "小区编码",
          render: (row) => <div>{row.community_code}</div>,
        },
        {
          prop: "name",
          label: "楼栋名称",
          render: (row) => <div>{row.name}</div>,
        },
        {
          prop: "code",
          label: "楼栋编号",
          render: (row) => <div>{row.code}</div>,
        },
        {
          prop: "resource_mac",
          label: "管理设备",
          render: (row) => <div>{row.resource_mac}</div>,
        },
        {
          prop: "create_time",
          label: "创建时间",
          render: (row) => <div>{row.create_time}</div>,
        },
        {
          prop: "",
          label: "编辑",
          render: (row) => (
              <div>
                <el-button type="text" icon="MessageBox" onClick={()=>this.resourceAll(row)}>
                  绑定设备
                </el-button>
                <el-button type="text" icon="Edit" onClick={()=>this.dataEdit(row)}>
                  编辑
                </el-button>
                <el-button type="text" icon="Delete" onClick={()=>this.del(row)}>
                  删除
                </el-button>
              </div>
          )
        },
      ],
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
    }
  },
  watch:{

  },
  created() {
    this.onLoad();
    communityAll().then(res=>{
      this.communityList = res.data.data;
    })
  },
  methods:{
    onbind(){
      let { mac, id } = this.formLabelAlign;
      bindingResource({
        mac, id
      }).then(res=>{
        if(res.data.success){
          ElMessage({message: res.data.code.message, type: 'success'});
          this.resourceVisible = false;
          this.onLoad();
        }
      })
    },
    resourceAll(row){
      getCommunityResourceAll({
        community_code: row.community_code
      }).then(res=>{
        this.resourceVisible = true;
        this.resourceList = res.data.data;
        this.formLabelAlign.id = row.id;
        this.formLabelAlign.mac = row.resource_mac;
      })
    },
    handleClose(done){
      done();
      this.$nextTick(()=>{
        this.form = {};
        this.$refs['dataform'].resetFields();
      })
    },
    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.onLoad();
    },

    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.onLoad();
    },

    onSearch(){
      this.page.current = 1;
      this.onLoad();
    },

    resetForm() {
      this.searchForm = {};
      this.onLoad();
    },

    del(row){
      ElMessageBox.confirm(
          '此操作将永久删除该数据, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        delData({id:row.id}).then(res=>{
          ElMessage({message: res.data.code.message, type: 'success'});
          this.page.current = 1;
          this.page.limit = 10;
          this.searchForm = {};
          this.onLoad();
        })
      }).catch(() => {})
    },
    editSave(){
      this.$refs.dataform.validate((valid) => {
        if (valid) {
          let {id, community_code, code, name} = this.form;
          editData({
            id, community_code, code, name
          }).then(res=>{
            if(res.data.success){
              ElMessage({message: res.data.code.message, type: 'success'});
              this.editFormVisible = false;
              this.$nextTick(()=>{
                this.form = {};
                this.$refs['dataform'].resetFields();
              })
              this.onLoad();
            }
          })
        } else {
          return false;
        }
      });
    },

    dataEdit(row){
      this.form = JSON.parse(JSON.stringify(row));
      this.editFormVisible = true;
    },
    onSave(){
      this.$refs.dataform.validate((valid) => {
        if (valid) {
          let { community_code, code, name } = this.form;
          addData({
            community_code, code, name
          }).then(res=>{
            if(res.data.success){
              ElMessage({message: res.data.code.message, type: 'success'});
              this.dialogFormVisible = false;
              this.page.current = 1;
              this.page.limit = 10;
              this.searchForm = {};
              this.$nextTick(()=>{
                this.form = {};
                this.$refs['dataform'].resetFields();
              })
              this.onLoad();
            }
          })
        } else {
          return false;
        }
      });
    },
    onLoad(){
      dataList({ limit:this.page.limit, page:this.page.current, ...this.searchForm }).then(res=>{
        this.tableData = res.data.data.list;
        this.page.total = res.data.data.count;
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>